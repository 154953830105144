import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceUUID } from 'device-uuid';
import { LoaderService } from '../loader/loader.service';
import { CommonHttpService } from '../utils/common-http.service';
import { decode } from 'hex-encode-decode';
import constant from '../app.constant';

@Component({
  selector: 'app-device-app',
  templateUrl: './device-app.component.html',
  styleUrls: ['./device-app.component.scss']
})
export class DeviceAppComponent implements OnInit {

  userId: any;
  storeId: any;
  reqJson: any = {}
  devicePlatform: any;
  isLoading: boolean = false;
  interval: any;
  constructor(private http: CommonHttpService, private activatedRoute: ActivatedRoute, private loaderService: LoaderService) {
  }


  ngOnInit(): void {
    // this.interval = setInterval(() => {
    //   this.isLoading = !this.isLoading;
    // }, 100);
    this.activatedRoute.paramMap.subscribe(async params => {
      this.reqJson.user_id = params.get('uid');
      this.reqJson.store_branch_id = params.get('sbid');
      this.reqJson.link_type = params.get('type');
      this.reqJson.ids = params.get('ids');
    });
    // this.activatedRoute.queryParams.subscribe(async params => {
    //   var param = params['en'];
    //   console.log(param)
    //   let text = param;
    //   const urlArray = text.split("()");
    //   urlArray.map((item: any) => {
    //     if (item.includes('cui-')) {
    //       this.reqJson.community_user_id = item.substring(4);
    //     }
    //     if (item.includes('tbo-')) {
    //       this.reqJson.table_id = item.substring(4);
    //     }
    //   });
    // });
    var originalText = decode(this.reqJson.ids);


    console.log(originalText);
    const urlArray = originalText.split("()");
    urlArray.map((item: any) => {
      if (item.includes('cui-')) {
        this.reqJson.community_user_id = item.substring(4);
      }
      if (item.includes('tbo-')) {
        this.reqJson.table_id = item.substring(4);
      }
    });


    var uuid = new DeviceUUID().get();
    var du = new DeviceUUID().parse();
    this.devicePlatform = du.platform;
    this.reqJson.os = this.devicePlatform
    this.reqJson.device_uuid = uuid;
    this.updateDeviceDetails();

  }
  @ViewChild('myDiv', { read: ElementRef }) myDiv!: ElementRef<HTMLElement>;
  @ViewChild("modalBasic") modalContent!: TemplateRef<any>;
  async checkDevice() {
    if (this.devicePlatform == "Microsoft Windows") {
      window.location.href = 'https://engaje.app/';
    } else if (this.devicePlatform == 'Android') {

      let el: HTMLElement = this.myDiv.nativeElement;
      console.log(el)
      el?.click();
      // var navigator: any = Navigator;
      // const relatedApps = await navigator?.getInstalledRelatedApps();
      // console.log("relatedApps", relatedApps);

      // relatedApps?.forEach((app: any) => {
      //   console.log(app.id, app.platform, app.url);
      // });
      // window.open("https://play.google.com/store/apps/details?=com.adloggs.engaje_assist_app", "_system");
      // window.open("market://details?id=com.adloggs.engaje_assist_app", "_system");
      // window.location.href = 'https://play.google.com/store/apps/details?id=com.adloggs.engaje_assist_app&hl=en';
      // document.location.href = "app-1-186004198474-ios-4974283795f4da958002b3://";

      // setTimeout(() => {

      //   if (confirm('You do not seem to have Your App installed, do you want to go download it now?')) {

      // document.open();
      // document.write("<a href='https://play.google.com/store/apps/details?id=com.adloggs.engaje_assist_app'>App link</a>")
      // document.close();
      // document.open('https://play.google.com/store/apps/details?id=com.adloggs.engaje_assist_app', '', 'noopener=false')
      // document.open("", 'noopener=true');
      // window.location.replace("market://details?id=com.adloggs.engaje_assist_app");
      // window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.adloggs.engaje_assist_app";
      // window.open("market://details?id=com.adloggs.engaje_assist_app", "_system");
      // var newloc = "http://schemas.android.com/apk/res/android";
      // window.open(newloc);

      // window.close();
      //   }
      // }, 300);
      // window.location.replace('https://play.google.com/store/apps/details?id=operamini');
    } else if (this.devicePlatform == 'iPhone') {
      // window.location.href = 'https://apps.apple.com/us/app/engaje/id1517774664?ls=1';
      document.location = "app-1-186004198474-ios-4974283795f4da958002b3://";
      setTimeout(function () {
        if (confirm('You do not seem to have Your App installed, do you want to go download it now?')) {
          document.location = 'https://apps.apple.com/us/app/engaje/id1517774664?ls=1';
          // window.close();
        }
      }, 300);
    }
  }
  updateDeviceDetails() {
    this.reqJson.store_branch_id = (this.reqJson.store_branch_id == 0) ? null : this.reqJson.store_branch_id
    console.log('reqJson', this.reqJson)
    this.http.postData(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.device.UpdateDevice, this.reqJson).subscribe((response: any) => {
      console.log(response)
      if (response.status) {
        clearInterval(this.interval);
        this.checkDevice();
      }
    })
  }

}
