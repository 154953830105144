import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeviceAppComponent } from './device-app/device-app.component';
import { InitialsyncComponent } from './initialsync/initialsync.component';

const routes: Routes = [
  { path: 'init/:uid/:sbid/:type/:ids', component: DeviceAppComponent },
  { path: 'sync/:token', component: InitialsyncComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
