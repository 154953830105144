import { environment } from './../../src/environments/environment';

class Constant {

    Client = {
        Auth: {
            BaseUrl: environment.Service.AuthBaseUrl
        }
    }

    Service = {
        MainMaster: {
            BaseUrl: environment.Service.EngajeLoginApiBaseUrl,
            BusinessBaseUrl: environment.Service.EngajeBusinessApiBaseUrl,
            Auth: {
                // Logout: 'user/session/logout'
            },
            device: {
                UpdateDevice: 'favorjoinfromlink/create',
                getIp: 'dynamic/getip',
                deviceCheck: 'favorjoinfromlink/app/favstoreandcomm'
            }
        }

    }
}


let constant = new Constant();
export default constant;