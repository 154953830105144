import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: "root"
})
export class LoaderService {
    public subject = new Subject();

    // public static onChange = new Subject<boolean>();
    // loadState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public dataSource = new BehaviorSubject({});
    // public data: Observable<boolean> = this.dataSource.asObservable();
    // public loadState = new BehaviorSubject<boolean>(false);
    // show(data: boolean) {
    //     this.dataSource.next(data);
    //     console.log('ffffffffffffff')
    // }
    public login = this.subject;
    checkLogin = this.subject.asObservable();
    // hide(data: boolean) {
    //     this.dataSource.next(data);
    //     console.log('hhhhhhhhh') 
    // }
}
