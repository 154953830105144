<div class="loader-container">
    <div class='loading'>
        <span class='dot dot-1'></span>
        <span class='dot dot-2'></span>
        <span class='dot dot-3'></span>
        <span class='dot dot-4'></span>
    </div>
    <!-- <div class="row" *ngIf="isLoading">
        <div class="loader-content" style="text-align: center;">
            <img style="width: 50px; height: 50px;" src="assets/images/logo.png" alt="">
        </div>
    </div> -->
</div>