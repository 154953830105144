import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CommonHttpService {



  constructor(private http: HttpClient) { }

  public postData(url: any, data: any, options?: any) {
    return this.http.post(url, data, options);
  }

  public get(url: any, options?: any) {
    return this.http.get(url, options);
  }

  public getDataById(url: any, id: any) {
    return this.http.get(url + '/' + id);
  }

  public updateData(url: any, data: any, options?: any) {
    return this.http.put(url, data, options);
  }

  public deleteData(url: any, id: any) {
    return this.http.delete(url + '/' + id);
  }

}

