import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import constant from '../app.constant';
import { DeviceUUID } from 'device-uuid';
import { CommonHttpService } from '../utils/common-http.service';
import { LoaderService } from '../loader/loader.service';


@Component({
  selector: 'app-initialsync',
  templateUrl: './initialsync.component.html',
  styleUrls: ['./initialsync.component.scss']
})
export class InitialsyncComponent implements OnInit {
  reqJson: any = {}
  devicePlatform: any;
  isLoading: boolean = false;
  interval: any;

  constructor(private http: CommonHttpService, private activatedRoute: ActivatedRoute, private loaderService: LoaderService) { }

  ngOnInit(): void {
    // this.interval = setInterval(() => {
    //   this.isLoading = !this.isLoading;
    // }, 100);
    this.activatedRoute.paramMap.subscribe(async params => {
      this.reqJson.token = params.get('token');
    });
    this.reqJson.device_uuid = new DeviceUUID().get();
    var du = new DeviceUUID().parse();
    this.devicePlatform = du.platform;
    this.deviceDetailCheck();
  }
  deviceDetailCheck() {
    this.http.postData(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.device.deviceCheck, this.reqJson).subscribe((response: any) => {
      if (response.status) {
        clearInterval(this.interval);
        if (this.devicePlatform == "Microsoft Windows") {
          window.location.href = 'https://engaje.app/';
        } else if (this.devicePlatform == 'Android') {
          // window.location.href = 'https://play.google.com/store/apps/details?id=com.adloggs.engaje_assist_app&hl=en';
          // document.location = "app-1-186004198474-ios-4974283795f4da958002b3://";
        } else if (this.devicePlatform == 'iPhone') {
          document.location = "app-1-186004198474-ios-4974283795f4da958002b3://";
        }
      }
    });
  }
}
